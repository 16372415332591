// Custom styles for this template

embed.liveView {
  width: 100%;
  height: 70vh;
}

iframe.liveView {
  width: 100%;
  border: 0;
  height: 70vh;
}


.fa-rotate-45 {
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  transform: rotate(45deg);
}

.fa-rotate-135 {
  -webkit-transform: rotate(135deg);
  -moz-transform: rotate(135deg);
  -ms-transform: rotate(135deg);
  -o-transform: rotate(135deg);
  transform: rotate(135deg);
}

.fa-rotate--45 {
  -webkit-transform: rotate(-45deg);
  -moz-transform: rotate(-45deg);
  -ms-transform: rotate(-45deg);
  -o-transform: rotate(-45deg);
  transform: rotate(-45deg);
}

.navbar {
  .form-inline {
    i {
      color: $navbar-color;
    }
    .form-control {
      margin-top: -3px;
      color: $navbar-color;
      border: 0;

      &::placeholder {
        color: $navbar-color;
      }
    }
    .form-control::-webkit-input-placeholder { color: $navbar-color; }
    .form-control:-moz-placeholder { color: $navbar-color; }
    .form-control::-moz-placeholder { color: $navbar-color; }
    .form-control:-ms-input-placeholder { color: $navbar-color; }
  }
  .nav-item {

    .nav-link.nav-pill {
      width: 32px;
      height: 32px;
      padding: 0;
      line-height: 32px;
      border: 1px solid $navbar-color;
      border-radius: 50em;

      &:hover {
        border-color: $navbar-hover-color;
      }

      .badge-pill {
        margin-top: -18px;
        margin-left: -5px;
        border: 2px solid #fff !important;
        line-height: 14px;
      }

      &.avatar {
        width: 38px;
        height: 38px;

        img {
          width: 36px;
          height: 36px;
          margin: 0;
          border: 0;
        }

        .badge {
          margin-top: -22px;
          margin-left: 5px;
        }

        &.dropdown-toggle:after {
          display: none;
        }
      }
    }
  }
}


.sidebar .sidebar-nav .nav {
  .nav-title {
    span {
      position: relative;
      display: inline-block;

      &:before, &:after {
        position: absolute;
        top: 50%;
        width: 25px;
        height: 1px;
        margin-top: -1px;
        content: '';
        border-bottom: 1px solid $sidebar-nav-title-color;
      }

      &:before {
        left: -30px;
      }

      &:after {
        right: -30px;
      }
    }
  }
}

// Here you can add other styles





// --------------------------------
// Inline Theme
// --------------------------------
a.c-ngx-edit-inline {
  text-decoration: none;
  color: #428bca;
  border-bottom: dashed 1px #428bca;
  cursor: pointer;
  line-height: 2;
  margin-right: 5px;
  margin-left: 5px;
}
.c-ngx-edit-inline.editable-empty,
.c-ngx-edit-inline.editable-empty:hover,
.c-ngx-edit-inline.editable-empty:focus,
.c-ngx-edit-inline.a.editable-empty,
.c-ngx-edit-inline.a.editable-empty:hover,
.c-ngx-edit-inline.a.editable-empty:focus {
  font-style: italic;
  color: #DD1144;
  text-decoration: none;
}

.c-ngx-edit-inline.inlineEditForm {
  display: inline-block;
  white-space: nowrap;
  margin: 0;
}

#inlineEditWrapper {
  display: inline-block;
}

.c-ngx-edit-inline.inlineEditForm input,
.c-ngx-edit-inline.select {
  width: auto;
  display: inline;
}

.c-ngx-edit-inline.ngx-edit-inline-button-group {
  display: inline-block;
}

.c-ngx-edit-inline.editInvalid {
  color: #a94442;
  margin-bottom: 0;
}

.c-ngx-edit-inline.error {
  border-color: #a94442;
}

[hidden].c-ngx-edit-inline {
  display: none;
}




//--------------------------------------
// Dragula
//--------------------------------------
/* in-flight clone */
.gu-mirror {
  position: fixed !important;
  margin: 0 !important;
  z-index: 9999 !important;
  opacity: 0.8;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=80)";
  filter: alpha(opacity=80);
  pointer-events: none;
}
/* high-performance display:none; helper */
.gu-hide {
  left: -9999px !important;
}
/* added to mirrorContainer (default = body) while dragging */
.gu-unselectable {
  -webkit-user-select: none !important;
  -moz-user-select: none !important;
  -ms-user-select: none !important;
  user-select: none !important;
}
/* added to the source element while its mirror is dragged */
.gu-transit {
  opacity: 0.2;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=20)";
  filter: alpha(opacity=20);
}



// --------------------------------

// --------------------------------
.ngx-dropdown-list-container {
  height:250px;
}

.vcenter {
  display:flex;
  align-items:center;
}
.overlayParent {
  position:relative;
}
.overlay {
  position: absolute;
  width: 100%; height: 100%;
  top: 0;left: 0;right: 0;bottom: 0;
  background-color: rgba(0,0,0,0.5);
  z-index: 5;
}

.verCodeFont {
  font-family: Inconsolata, Monaco, Consolas, 'Courier New', Courier;
}

// --------------------------------
// Widget Spinner
// --------------------------------
widget-spinner{
  position:absolute;
  width:100%;
  height:100%;
}
widget-spinner > .sk-circle {
  margin:auto;
  position:relative;
  height:40px;
  top:50%;
  transform:translateY(-20px);
}
.spinner-holder {
  width:100%;
  height:100px
}


// --------------------------------
//
// --------------------------------

.formula-tree {
  .node {
    cursor:default;
  }

  .node text {
    font: 12px sans-serif;
    fill: #FFFFFF;
  }

  .node rect {
  }

  /* Lines */
  .link {
    fill: none;
    stroke: #424242;
    stroke-width: 1.5px;
  }
}

// --------------------------------
// Table Stuff
// --------------------------------

.div-table {

}


.div-table-light.div-table-th {
  background-color: #e8fde7;
  color: black;
}



.div-table-light.div-table-tr {
  background-color: #f2f9fc;
}

.div-table-light.div-table-tr-alt {
  background-color: #f0ce6e;
}


.div-table-th {
  background-color: #678898;
  color: white;
  font-size: 1.2em;
}

.div-table-tr {
  background-color: #E1E6EF;
}

.div-table-tr-alt {
  background-color: #9FAECB;
}

.nested {
  .div-table-tr {
    border-bottom-width: 1px;
    border-bottom-color: black;
  }

  .div-table-tr-alt {
    border-bottom-width: 1px;
    border-bottom-color: black;
  }

  .nested {
    .div-table-tr {
      border-bottom-width: 1px;
      border-bottom-color: gray;
    }

    .div-table-tr-alt {
      border-bottom-width: 1px;
      border-bottom-color: gray;
    }
  }
}


.div-table-tr-sel {
  border: 1px solid black;
}


// Borders
.div-table.div-table-border{
  border: 1px solid #c0cadd;
}

.div-table.div-table-border  .div-table-th{
  border-bottom: 2px solid #c0cadd;
  border-left: 1px solid #c0cadd;
}

.div-table.div-table-border  .div-table-th:first-of-type{
  border-bottom: 2px solid #c0cadd;
  border-left: 0px solid #c0cadd;
}

.div-table.div-table-border  .div-table-tr{
  border-left: 1px solid #c0cadd;
}

.div-table.div-table-border  .div-table-tr:first-of-type{
  border-left: 0px solid #c0cadd;
}

.div-table.div-table-border  .div-table-tr-alt{
  border-left: 1px solid #c0cadd;
}

.div-table.div-table-border  .div-table-tr-alt:first-of-type{
  border-left: 0px solid #c0cadd;
}

// Colors
.div-table.div-table-classic {
  font-size: .875em;
  font-family: Inconsolata, Monaco, Consolas, 'Courier New', Courier;
}

.div-table-classic  .div-table-th{
  font-size: 1.2em;
  background-color: white;
  font-weight: bold;
  color: #151b1e;
  padding: 0.3rem !important;
}

.div-table-classic  .div-table-tr{
  font-size: 1.2em;
  background-color: #EEEEEE;
  padding: 0.3rem !important;
  color: #151b1e;
}

.div-table-classic  .div-table-tr-alt{
  font-size: 1.2em;
  background-color: white;
  padding: 0.3rem !important;
  color: #151b1e;
}


.text-green { color: green !important; }



.text-carousel.carousel-control-prev,
.text-carousel.carousel-control-next {
  width: 5%;
}



ng-select {
  &.form-control-lg {
    height: calc(1.5em + 1rem + 2px);
    font-size: 1.09375rem;
    line-height: 1.5;
    padding: 0rem;
    vertical-align: unset;
    background-color: white;
    font-weight: 400;
    color: #151b1e;
    .below {
      padding: 0.5rem 0rem;
    }

    > select-dropdown > div .options {
      background-color: white;
      border: 1px solid #ccc;
      border-top: 0px;
    }
  }

  &.ng-select-inline {
    width: unset;
  }
  &.ng-select-inline-1 {
    width: 8.33333333%;
  }
  &.ng-select-inline-2 {
    width: 16.66666667%;
  }
  &.ng-select-inline-3 {
    width: 25%;
  }
  &.ng-select-inline-4 {
    width: 33.33333333%;
  }
  &.ng-select-inline-5 {
    width: 41.66666667%;
  }
  &.ng-select-inline-6 {
    width: 50%;
  }
  &.ng-select-inline-7 {
    width: 58.33333333%;
  }
  &.ng-select-inline-8 {
    width: 66.66666667%;
  }
  &.ng-select-inline-9 {
    width: 75%;
  }
  &.ng-select-inline-10 {
    width: 83.33333333%;
  }
  &.ng-select-inline-11 {
    width: 91.66666667%;
  }
  &.ng-select-inline-12 {
    width: 100%;
  }
}


.md-modal {
  width: 600px;
  max-width:90vw;
}

.lg-modal {
  width: 800px;
  max-width:90vw;
}


.xl-modal {
  min-height:80vh;
  min-width:80vw;
}

.xl-2-modal {
  min-height:90vh;
  min-width:90vw;
}



.spinner {
  background-color: #333;
  width: 16px;
  height: 16px;
  margin: 0px auto;
  -webkit-animation: sk-rotateplane 1.2s infinite ease-in-out;
  animation: sk-rotateplane 1.2s infinite ease-in-out;
}

.spinner.spinner-white{
  background-color: #FFF;
}


.spinner.spinner-dark{
  background-color: #000;
}

.spinner.sm {
  width: 16px;
  height: 16px;
  margin: 0px auto;
}

.spinner.md {
  width: 40px;
  height: 40px;
  margin: 100px auto;
}


.spinner.lg {
  width: 100px;
  height: 100px;
  margin: 200px auto;
}




.border-black {
  border-color: #000 !important;
}

.min-h-10 { min-height: 10% !important; }
.min-h-20 { min-height: 20% !important; }
.min-h-30 { min-height: 30% !important; }
.min-h-40 { min-height: 40% !important; }
.min-h-50 { min-height: 50% !important; }
.min-h-60 { min-height: 60% !important; }
.min-h-70 { min-height: 70% !important; }
.min-h-80 { min-height: 80% !important; }
.min-h-90 { min-height: 90% !important; }
.min-h-100 { min-height: 100% !important; }




.min-vh-10 { min-height: 10vh !important; }
.min-vh-20 { min-height: 20vh !important; }
.min-vh-30 { min-height: 30vh !important; }
.min-vh-40 { min-height: 40vh !important; }
.min-vh-50 { min-height: 50vh !important; }
.min-vh-60 { min-height: 60vh !important; }
.min-vh-70 { min-height: 70vh !important; }
.min-vh-80 { min-height: 80vh !important; }
.min-vh-90 { min-height: 90vh !important; }

.min-w-1 { min-width: 1% !important; }
.min-w-5 { min-width: 5% !important; }
.min-w-10 { min-width: 10% !important; }
.min-w-20 { min-width: 20% !important; }
.min-w-30 { min-width: 30% !important; }
.min-w-40 { min-width: 40% !important; }
.min-w-50 { min-width: 50% !important; }
.min-w-60 { min-width: 60% !important; }
.min-w-70 { min-width: 70% !important; }
.min-w-80 { min-width: 80% !important; }
.min-w-90 { min-width: 90% !important; }
.min-w-100 { min-width: 100% !important; }




.max-w-1 { max-width: 1% !important; }
.max-w-5 { max-width: 5% !important; }
.max-w-10 { max-width: 10% !important; }
.max-w-20 { max-width: 20% !important; }
.max-w-30 { max-width: 30% !important; }
.max-w-40 { max-width: 40% !important; }
.max-w-50 { max-width: 50% !important; }
.max-w-60 { max-width: 60% !important; }
.max-w-70 { max-width: 70% !important; }
.max-w-80 { max-width: 80% !important; }
.max-w-90 { max-width: 90% !important; }
.max-w-100 { max-width: 100% !important; }


@-webkit-keyframes sk-rotateplane {
  0% { -webkit-transform: perspective(120px) }
  50% { -webkit-transform: perspective(120px) rotateY(180deg) }
  100% { -webkit-transform: perspective(120px) rotateY(180deg)  rotateX(180deg) }
}

@keyframes sk-rotateplane {
  0% {
    transform: perspective(120px) rotateX(0deg) rotateY(0deg);
    -webkit-transform: perspective(120px) rotateX(0deg) rotateY(0deg)
  } 50% {
      transform: perspective(120px) rotateX(-180.1deg) rotateY(0deg);
      -webkit-transform: perspective(120px) rotateX(-180.1deg) rotateY(0deg)
    } 100% {
        transform: perspective(120px) rotateX(-180deg) rotateY(-179.9deg);
        -webkit-transform: perspective(120px) rotateX(-180deg) rotateY(-179.9deg);
      }
}


.footer-fixed .app-footer {
  z-index: 1000;
}
